import React, { Component, useEffect, useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  array,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, Button, IconCollection, InlineTextButton } from '../../components';
import { BookingTimeForm } from '../../forms';
import { SELECTED_TIME } from '../../util/dates';
import { truncate } from 'lodash';
import Skeleton from 'react-loading-skeleton'
import '../../assets/react-loading-skeleton.css';
import css from './BookingPanel.module.css';

const BIO_COLLAPSED_LENGTH = 170;
const truncated = s => {
  return truncate(s, {
    length: BIO_COLLAPSED_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};
class ExpandableBio extends Component {
  constructor(props) {
    super(props);
    this.state = { expand: false };
  }
  render() {
    const { expand } = this.state;
    const { className, bio } = this.props;
    const truncatedBio = truncated(bio);

    const handleShowMoreClick = () => {
      this.setState({ expand: true });
    };
    const handleShowLessClick = () => {
      this.setState({ expand: false });
    };
    const showMore = (
      <InlineTextButton rootClassName={css.showMore} onClick={handleShowMoreClick}>
        <FormattedMessage id="UserCard.showFullBioLink" />
      </InlineTextButton>
    );
    const showLess = (
      <InlineTextButton rootClassName={css.showMore} onClick={handleShowLessClick}>
        <FormattedMessage id="UserCard.showLessBioLink" />
      </InlineTextButton>
    );
    return (
      <p className={className}>
        {expand ? bio : truncatedBio}
        {bio !== truncatedBio && !expand ? showMore : expand == false ? null : showLess}
      </p>
    );
  }
}

ExpandableBio.defaultProps = { className: null };

ExpandableBio.propTypes = {
  className: string,
  bio: string.isRequired,
};
// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = {
  month: 'short',
  day: 'numeric',
  weekday: 'short',
};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    description,
    geolocation,
    publicData,
    listingId,
    address,
    isMobile,
    timeSlots,
    formetZipLocation,
    distanceInMiles
  } = props;
  const [timeValue, setTimeValue] = useState(listing && listing.attributes.publicData?.sixHoursRental ? "sixHours" : "day")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [duration, setDuration] = useState(null);
  const price = listing.attributes.price;
  const [isSelectedPrice, setisSelectedPrice] = useState(publicData?.sixHoursRental && timeValue == "sixHours" ? Math.round((price.amount * 60 / 100) / 100) : timeValue == "1" ? Math.round((price.amount * 70 / 100) / 100) : timeValue == "day" ? (price.amount / 100) : null);
  const timeZone =
    listing.attributes.availabilityPlan &&
    listing.attributes.availabilityPlan.timezone;
  const hasListingState = !!listing.attributes.state;
  const isClosed =
    hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingTimeForm = hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isBook = !!parse(location.search).book;

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
      ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
      : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
      ? 'BookingPanel.perDay'
      : 'BookingPanel.perDay';

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);


  const handleModal = value => {
    setIsModalOpen(value);
  }
  const splitted = address && address?.split(",");
  const stateName = splitted && splitted.length == 4 ? splitted[2] : splitted && splitted.length == 5 ? splitted[3] : splitted && splitted[1];
  const cityName = splitted && splitted.length == 4 ? splitted[1] : splitted && splitted.length == 5 ? splitted[2] : splitted && splitted[0]
  const stateName1 = stateName?.split(" ")[1] + " " + stateName?.split(" ")[2]
  const finalState = stateName?.split(" ").length == 4 ? stateName1 : stateName?.split(" ")[1]

  const handleSelectTime = () => {
    setTimeValue('sixHours');
  }

  const handleSelectDayTime = () => {
    setTimeValue('day');
  }

  const handleSelectMultiTime = () => {
    setTimeValue('1');
  }

  return (
    <div className={classes}>
      {isOwnListing
        ? null
        : <div className={css.locationWrapper} onClick={() => {
          window.open(` https://www.google.com/maps/search/?api=1&query=${geolocation.lat},${geolocation.lng}`, "_blank")
        }}>
          <IconCollection name="LISTING_MAP_LOCATION" />
          <span className={css.locationText}>
            {cityName ? cityName : null}, {finalState ? finalState : null}
          </span>
          <span className={css.locationText}>{distanceInMiles ? <>({distanceInMiles.toFixed(0) <= 10 ? distanceInMiles.toFixed(1) : distanceInMiles.toFixed(0)} mi)</> : <Skeleton width={50} height={10} />}</span>
        </div>}

      {isBook && isMobile
        ? null
        : <div className={css.bookingHeading}>
          {!publicData?.sixHoursRental
            ? null
            : <div className={timeValue == "sixHours" ? classNames(
              css.desktopPriceContainer,
              css.activeLink
            ) : css.desktopPriceContainer} onClick={() => {
              SELECTED_TIME.push("sixHours")
              handleSelectTime()
              setisSelectedPrice(Math.round(price.amount * 60 / 10000))
            }}>
              <div className={css.desktopPriceValue} title={priceTitle}>
                ${Math.round((price.amount * 60 / 100) / 100)}
              </div>
              <div className={css.desktopPerUnit}>
                <FormattedMessage id="BookingPanel.sixHoursText" />
              </div>
            </div>}

          <div
            className={timeValue == "day"
              ? classNames(css.desktopPriceContainer,
                css.activeLink)
              : css.desktopPriceContainer}
            onClick={() => {
              handleSelectDayTime()
              SELECTED_TIME.splice(0, 1)
              setisSelectedPrice((price.amount) / 100)
            }}
          >
            <div className={css.desktopPriceValue} title={priceTitle}>
              {formattedPrice}
            </div>
            <div className={css.desktopPerUnit}>
              <FormattedMessage id="BookingPanel.oneDayText" />
            </div>
          </div>
          {publicData?.multiDayRental
            ? <div className={timeValue == "1" ? classNames(
              css.desktopPriceContainer,
              css.activeLink
            ) : css.desktopPriceContainer} onClick={() => {
              handleSelectMultiTime()
              setisSelectedPrice(Math.round(price.amount * 70 / 10000))
            }}>
              <div className={css.desktopPriceValue} title={priceTitle}>
                ${Math.round(price.amount * 70 / 10000)}
              </div>
              <div className={css.desktopPerUnit}>
                <FormattedMessage id="BookingPanel.moreDaysText" />
              </div>
            </div>
            : null}
        </div>}

      {isOwnListing
        ? <>
          <div className={css.deliveredWrapper}>
            <div>
              <h5><FormattedMessage id="ListingPage.instantBookingTitle" /></h5>
              <span>{publicData.instantBooking === "delivery" ? <p className={css.onText}><FormattedMessage id="TransactionPanel.offText" /></p> : <p className={css.offText}><FormattedMessage id="TransactionPanel.onText" /></p>}</span>
            </div>
            <span className={css.desktopLine}>           <svg width="2" height="36" viewBox="0 0 2 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" width="1" height="36" fill="#D8DAD9" />
            </svg></span>
            <span className={css.mobileLine}><svg width="1" height="48" viewBox="0 0 1 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="1" height="48" fill="#D8DAD9" />
            </svg>
            </span>

            <div >
              <h5>
                <FormattedMessage id="ListingPage.deliveryAndPickup" />
              </h5>
              <span>
                {!publicData?.deliveryOption ? <p className={css.offText}><FormattedMessage id="TransactionPanel.offText" /></p> : <p className={css.onText}><FormattedMessage id="TransactionPanel.onText" /></p>}
              </span>
            </div>
          </div>
          <div className={css.sectionDescription}>
            <h2 className={css.descriptionTitle}>
              <FormattedMessage id="ListingPage.descriptionTitle" />
            </h2>
            {description ? <ExpandableBio className={css.mobileBio} bio={description} /> : null}
          </div>
        </>
        : null}

      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingTimeFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        bookingPanel={true}
        isOwnListing={isOwnListing}
      >
        {isOwnListing || isModalOpen ? null : <h1 className={css.titleBooking}><FormattedMessage id="TransactionPanel.selectDateTime" /></h1>}
        {showBookingTimeForm ? (
          <BookingTimeForm
            duration={duration}
            bookingPanelMobile={true}
            isSelectedPrice={isSelectedPrice}
            isModalOpen={isModalOpen}
            handleModal={handleModal}
            className={css.bookingForm}
            handleSelectDayTime={handleSelectDayTime}
            handleSelectMultiTime={handleSelectMultiTime}
            handleSelectTime={handleSelectTime}
            isBook={isBook}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.submitButtonWrapper}
            unitType={unitType}
            onSubmit={(e) => {
              onSubmit(e)
            }}
            timeValue={timeValue}
            price={price}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            listing={listing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            onManageDisableScrolling={onManageDisableScrolling}
            timeZone={timeZone}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            geolocation={geolocation}
            publicData={publicData}
            formattedPrice={formattedPrice}
            priceTitle={priceTitle}
            address={address}
            isMobile={isMobile}
            timeSlots={timeSlots}
            distanceInMiles={distanceInMiles}
            description={description}
            instantBooking={publicData?.instantBooking}
          />
        ) : null}
      </ModalInMobile>

      {isOwnListing
        ? null
        : <div className={css.openBookingForm}>
          <div className={css.priceContainer}>
            <div className={css.priceValue} title={priceTitle}>
              ${timeValue == "sixHours"
                ? Math.round((price.amount * 60 / 100) / 100)
                : timeValue == "day"
                  ? parseInt((price.amount) / 100)
                  : timeValue == "1"
                    ? Math.round((price.amount * 70 / 100) / 100)
                    : null}
            </div>
            <div className={css.perUnit}>
              {timeValue == "sixHours"
                ? <FormattedMessage id="BookingPanel.sixHoursText" /> : timeValue == "day" ? "Day" : timeValue == "1" ? 'Day'
                  : null}
            </div>
          </div>

          {showBookingTimeForm ? (
            <Button
              rootClassName={css.bookButton}
              onClick={() => {
                openBookModal(isOwnListing, isClosed, history, location)
                // setIsModalOpen(true)

                setDuration(timeValue)
              }}
            >
              <FormattedMessage id="BookingPanel.ctaButtonMessage" />
            </Button>
          ) : isClosed ? (
            <div className={css.closedListingButton}>
              <FormattedMessage id="BookingPanel.closedListingButtonText" />
            </div>
          ) : null}
        </div>}
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  distance: 0,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  distance: number,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import css from './MainDashboard.module.css';
import ReactChart from '../../Chart/ReactChart';
import SectionReviews from '../../../containers/ListingPage/SectionReviews';
import { useSelector } from 'react-redux';
import { createSlug } from '../../../util/urlHelpers';
import NamedLink from '../../NamedLink/NamedLink';
import IconCollection from '../../IconCollection/IconCollection';
import {
  BarChart,
  Bar,
  Cell,
  Legend,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import Menu from '../../Menu/Menu';
import MenuIcon from '../../ManageListingCard/MenuIcon';
import MenuLabel from '../../MenuLabel/MenuLabel';
import MenuContent from '../../MenuContent/MenuContent';
import MenuItem from '../../MenuItem/MenuItem';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Modal from '../../Modal/Modal';
import { IsDraftListing } from '../../../marketplace-custom-config';
import { REVIEW_TYPE_OF_PROVIDER } from '../../../util/types';
import {
  currentYearName,
  previousYearName,
} from '../../../containers/DashboardPage/DashboardPage';
import { InlineTextButton } from '../../Button/Button';

import profilePic from './../../../assets/business-profile-dp.png';
import IconAmenities from '../../IconAmenities/IconAmenities';
import { calculateAverageUserReview, totalUserReviewCount } from '../../../util/data';
import { ensureURL } from '../../../containers/BusinessProfileSelf/BusinessProfileSelf';
import { AvatarLarge } from '../../Avatar/Avatar';

const calculateAverage = reviews => {
  if (reviews?.length === 0) {
    return 0;
  }
  const sum =
    reviews &&
    reviews?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.attributes.rating,
      0
    );
  const average = sum / reviews?.length;
  return average.toFixed(1);
};

const ALL_TIME = 'All time';
const CURRENT_YEAR = `Current year ${currentYearName}`;
const LAST_YEAR = `Last year ${previousYearName}`;

const CustomTooltip = ({ active, payload, label, data }) => {
  const rentals = data.find(item => item.month === label)?.Rentals;
  if (active) {
    return (
      <div className={css.customTooltip}>
        {payload.map((item, idx) => (
          <div key={idx} className={css.tooltipContent}>
            <span className={css.label}>Earned:</span>{' '}
            <span className={css.label}>${item.value}</span>
          </div>
        ))}

        <div className={css.tooltipContent}>
          <span className={css.label}>Rentals:</span>{' '}
          <span className={css.label}>{rentals}</span>
        </div>
      </div>
    );
  }

  return null;
};

const MainDashboard = props => {
  const {
    TotalAmount,
    reviews,
    monthTotalAmounts,
    onVacationListing,
    onManageDisableScrolling,
    OwnListing,
    currentUser,
    currentYearDetails,
    previousYearDetails,
  } = props;
  const { requestBookings, totalRentals } = props.Booking;
  const inVacationMode =
    (currentUser &&
      currentUser.id &&
      currentUser.attributes.profile.publicData.isVacationMode) ||
    false;
  const IsDraftArray =
    OwnListing &&
    OwnListing.length > 0 &&
    OwnListing.filter((item, idx) =>
      IsDraftListing.includes(item?.attributes.state)
    );

  const IsDraftStatus = IsDraftListing.includes(
    IsDraftArray[0]?.attributes?.state
  );
  const yAxisAmount =
    Math.max(
      ...Object.entries(monthTotalAmounts).map(
        ([monthName, totalAmount]) => totalAmount.amount / 100
      )
    ) * 1.1;
  const [filter, setFilter] = useState(ALL_TIME);

  const barData = Object.entries(
    filter === CURRENT_YEAR
      ? currentYearDetails?.monthTotalAmounts
      : filter === LAST_YEAR
        ? previousYearDetails?.monthTotalAmounts
        : monthTotalAmounts
  ).map(([monthName, totalAmount]) => ({
    name: '',
    month: monthName.slice(0, 3),
    Total: totalAmount.amount / 100,
    Rentals: totalAmount.rentals,
    amt: yAxisAmount,
  }));
  const minAmountTotal = Math.min(
    ...Object.entries(barData).map(
      ([monthName, totalAmount]) => totalAmount.Total
    )
  );
  const maxAmountTotal = Math.max(
    ...Object.entries(barData).map(
      ([monthName, totalAmount]) => totalAmount.Total
    )
  );
  // range from minAmountTotal to maxAmountTotal
  const ticks = [
    0,
    ...Array(5)
      .fill(null)
      .map((_, idx) =>
        Math.round(
          minAmountTotal + ((maxAmountTotal - minAmountTotal) / 5) * (idx + 1)
        )
      ),
  ].map(val => Math.ceil(val / 5) * 5);
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 767;
  const [openModal, setopenModal] = useState(false);
  const handleModel = () => {
    setopenModal(!openModal);
  };
  const publicData = currentUser?.attributes?.profile?.publicData;
  const reviewsOfProvider = reviews.filter(
    r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER
  );
  const averageProviderReview = calculateAverageUserReview(reviewsOfProvider, []);
  const reviewCountProvider = totalUserReviewCount(reviewsOfProvider.length, []);

  const isDraft = (
    <Modal
      id="SettingPage.isDraft"
      settingVacation={true}
      isOpen={openModal}
      onClose={() => {
        setopenModal(false);
      }}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <div className={css.modalContainer}>
        <h5>Please finish your draft listings first</h5>
        <NamedLink name="ManageListingPage">Click</NamedLink>
      </div>
    </Modal>
  );
  return (
    <>
      <div className={css.main_wrapper}>
        <div className={css.main}>
          <div className={css.totalIncomeWrapper}>
            <div className={css.totalAmount}>
              <div className={css.icon}>
                <IconCollection name="TOTAL_INCOME_DOLLAR" />
              </div>
              <div className={css.Amount}>
                <h6>Total Income</h6>
                <h2>
                  $
                  {(filter === CURRENT_YEAR
                    ? currentYearDetails?.totalAmount
                    : filter === LAST_YEAR
                      ? previousYearDetails?.totalAmount
                      : TotalAmount) / 100}
                </h2>
              </div>
            </div>
            <div className={css.viewDetails}>
              <Menu>
                <MenuLabel className={css.menuLabel}>
                  {filter}
                  <IconCollection name="ARROW_dOWN_GREY" />
                </MenuLabel>
                <MenuContent rootClassName={css.menuContent}>
                  <MenuItem key={ALL_TIME}>
                    <InlineTextButton onClick={() => setFilter(ALL_TIME)}>
                      {ALL_TIME}
                    </InlineTextButton>
                  </MenuItem>
                  <MenuItem key={CURRENT_YEAR}>
                    <InlineTextButton onClick={() => setFilter(CURRENT_YEAR)}>
                      {CURRENT_YEAR}
                    </InlineTextButton>
                  </MenuItem>
                  <MenuItem key={LAST_YEAR}>
                    <InlineTextButton onClick={() => setFilter(LAST_YEAR)}>
                      {LAST_YEAR}
                    </InlineTextButton>
                  </MenuItem>
                </MenuContent>
              </Menu>
              {/* <button className={css.view}>View Detalis</button> */}
            </div>
          </div>
          <div className={css.barChartWrapper}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={150}
                height={40}
                data={barData}
                barCategoryGap={2}
              >
                <XAxis
                  tickLine={false}
                  axisLine={false}
                  dataKey="month"
                  tick={{ fill: 'rgba(14, 18, 62, 0.40)' }}
                  style={{
                    fontSize: isMobile ? '9px' : '12px',
                  }}
                />
                <YAxis
                  tickLine={false}
                  axisLine={false}
                  dataKey="amt"
                  tick={{ fill: 'rgba(14, 18, 62, 0.40)' }}
                  style={{
                    fontSize: isMobile ? '10px' : '12px',
                  }}
                  ticks={ticks}
                  tickFormatter={value => `$${value}`}
                />
                <Tooltip content={<CustomTooltip data={barData} />} />
                <Bar
                  dataKey="Total"
                  stackId="a"
                  fill="#C8F9EF"
                  radius={[10, 10, 0, 0]}
                  minPointSize={5}
                />
                {/* <Bar
                radius={[6, 6, 0, 0]}
                dataKey="Rentals"
                stackId="a"
                fill="#1fd1bc"
              /> */}
                {/* <Legend /> */}
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className={css.left_boxes}>
          {!!publicData.businessName && <div className={css.profileHeader}>
            <div>
              <AvatarLarge
                className={css.profilePic}
                user={currentUser}
              />
            </div>
            <div>
              <h3 className={css.profileTitle}>{publicData?.businessName}</h3>
              <div className={css.ratingBox}>
                <IconAmenities type="star" />
                <span>{averageProviderReview} ({reviewCountProvider})</span>

              </div>
              <div className={css.verifiedBox}>
                <span className={css.verifiedIcon}>
                  <IconAmenities type="verified_icon" />
                  <span>Verified Business</span>
                </span>
                <span>
                  <IconAmenities type="edit" />
                </span>
              </div>
              <div className={css.social_icons}>
                {!!publicData?.website && <a href={ensureURL(publicData?.website)} target="_blank">
                  <IconAmenities type="website_gray" />
                </a>}
                {!!publicData?.zipLocation && <a href={`https://www.google.com/maps/search/?api=1&query=${publicData?.zipLocation}`} target="_blank">
                  <IconAmenities type="location_gray" />
                </a>}

                {publicData?.facebook && <a href={ensureURL(publicData?.facebook)} target="_blank">
                  <IconAmenities type="facebook_gray" />
                </a>}

                {!!publicData?.instagram && <a href={ensureURL(publicData?.instagram)} target="_blank">
                  <IconAmenities type="instagram_gray" />
                </a>}

                {!!publicData?.linkedIn && <a href={ensureURL(publicData?.linkedIn)} target="_blank">
                  <IconAmenities type="linkedin_gray" />
                </a>}

                {!!publicData?.twitter && <a href={ensureURL(publicData?.twitter)} target="_blank">
                  <IconAmenities type="twitter_gray" />
                </a>}
              </div>
            </div>
          </div>}

          <NamedLink
            name={currentUser?.attributes?.profile?.publicData?.businessName ? "BusinessProfileSelfPage" : "ProfilePage"}
            params={{ id: currentUser?.id?.uuid }}
            to={{ isReview: REVIEW_TYPE_OF_PROVIDER }}
          >
            <div className={css.reviewCard}>
              <div className={css.leftContent}>
                <span className={css.reviewIcon}>
                  <IconCollection name="REVIEW_ICON" />
                </span>
                <div>
                  <span className={css.reviewContents}>
                    {calculateAverage(reviews)}{' '}
                    <span className={css.reviewText}> Reviews</span>
                  </span>
                </div>
              </div>
              <div className={css.arrowRight}>
                <IconCollection name="ARROW_RIGHT" />
              </div>
            </div>
          </NamedLink>
          <div className={css.cardWrapper}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span className={css.settingText}>Setting</span>
              <span className={css.settingText}>Coming Soon</span>
            </div>
            <div className={css.mainContents}>
              <div className={css.totalAmount}>
                <div className={css.cardTitle}>
                  <h6>
                    <FormattedMessage id="SettingPage.GeneralAvailability" />
                  </h6>
                </div>
                <div>
                  <Menu>
                    <MenuLabel className={css.menuLabel}>
                      <IconCollection name="DASHBOARD_INFO" />
                    </MenuLabel>
                    <MenuContent rootClassName={css.menuContentGenAvail}>
                      <MenuItem key="coming">Coming Soon</MenuItem>
                    </MenuContent>
                  </Menu>
                </div>
              </div>
              <div className={css.arrowRight}>
                <IconCollection name="ARROW_RIGHT" />
              </div>
            </div>
            {isDraft}
          </div>
          <div className={css.cardWrapper}>
            <span className={css.settingText}>Setting</span>
            <div className={css.mainContents}>
              <div className={css.totalAmount}>
                <div className={css.cardTitle}>
                  <h6>
                    <FormattedMessage id="SettingPage.vacationMode" />
                  </h6>
                </div>
                <div>
                  <Menu>
                    <MenuLabel
                      className={css.menuLabel}
                    // isOpenClassName={css.listingMenuIsOpen}
                    >
                      <IconCollection name="DASHBOARD_INFO" />
                    </MenuLabel>
                    <MenuContent rootClassName={css.menuContentVacation}>
                      {/* <MenuItem key="LAST">What is Vacation Mode?</MenuItem> */}
                      <MenuItem key="2021">
                        If you know you will be out of town or just unable to
                        manage your account and you don't want your response
                        rate to be affected by declined booking requests and
                        slow replies you can turn on Vacation Mode and pause
                        your account.
                      </MenuItem>
                    </MenuContent>
                  </Menu>
                </div>
              </div>
              <div className={css.viewDetails}>
                <h6>
                  {/* {true ? (
                <FormattedMessage id="SettingPage.on" />
              ) : (
                <FormattedMessage id="SettingPage.off" />
              )} */}
                </h6>
                <div
                  className={css.checkboxWrapper}
                  onClick={() => {
                    if (IsDraftStatus) {
                      handleModel();
                    } else {
                      onVacationListing(inVacationMode ? 'Close' : 'Open');
                    }
                  }}
                >
                  <div
                    className={classNames(css.ball, {
                      [css.toggled]: inVacationMode,
                    })}
                  ></div>
                </div>
              </div>
            </div>
            {isDraft}
          </div>
        </div>
      </div>

      <div className={css.bottomCard}>
        <div className={css.card}>
          <div className={css.iconWrapper}>
            <span className={css.clockIcon}>
              <IconCollection name="DASHBOARD_CLOCK" />
            </span>
            <Menu>
              <MenuLabel className={css.menuLabelComing}>
                <IconCollection name="DASHBOARD_INFO" />
              </MenuLabel>
              <MenuContent rootClassName={css.menuContentComing}>
                <MenuItem key="coming">Coming Soon</MenuItem>
              </MenuContent>
            </Menu>
          </div>
          <div className={css.bottomCardText}>
            <h3 style={{ margin: 0, padding: 0, fontSize: '18px' }}>
              {totalRentals}
            </h3>
            <h5 style={{ margin: 0, padding: 0 }}>Total Rentals</h5>
          </div>
        </div>
        <div className={css.card}>
          <div className={css.iconWrapper}>
            <span className={css.eyeIcon}>
              <IconCollection name="EYE_ICON" />
            </span>
            <Menu>
              <MenuLabel className={css.menuLabelComing}>
                <IconCollection name="DASHBOARD_INFO" />
              </MenuLabel>
              <MenuContent rootClassName={css.menuContentComing}>
                <MenuItem key="coming">Coming Soon</MenuItem>
              </MenuContent>
            </Menu>
          </div>

          <div className={css.bottomCardText}>
            <h3 style={{ margin: 0, padding: 0, fontSize: '18px' }}>
              {requestBookings ? requestBookings : 0}
            </h3>
            <h5 style={{ margin: 0, padding: 0 }}>Total Views</h5>
          </div>
        </div>
        <div className={css.card}>
          <div className={css.iconWrapper}>
            <span className={css.thumbsUp}>
              <IconCollection name="DASHBOARD_CLIP_BOARD" />
            </span>
            <Menu>
              <MenuLabel className={css.menuLabelComing}>
                <IconCollection name="DASHBOARD_INFO" />
              </MenuLabel>
              <MenuContent rootClassName={css.menuContentComing}>
                <MenuItem key="coming">Coming Soon</MenuItem>
              </MenuContent>
            </Menu>
          </div>
          <div className={css.bottomCardText}>
            <h3 style={{ margin: 0, padding: 0, fontSize: '18px' }}>0%</h3>
            <h5 style={{ margin: 0, padding: 0 }}>Acceptance Rate</h5>
          </div>
        </div>
        <div className={css.card}>
          <div className={css.iconWrapper}>
            <span className={css.dashboardStar}>
              <IconCollection name="DASHBOARD_STAR" />
            </span>
            <Menu>
              <MenuLabel
                className={css.menuLabelComing}
              // isOpenClassName={css.listingMenuIsOpen}
              >
                <IconCollection name="DASHBOARD_INFO" />
              </MenuLabel>
              <MenuContent rootClassName={css.menuContentComing}>
                <MenuItem key="coming">Coming Soon</MenuItem>
              </MenuContent>
            </Menu>
          </div>
          <div className={css.bottomCardText}>
            <h3 style={{ margin: 0, padding: 0, fontSize: '18px' }}>0%</h3>
            <h5 style={{ margin: 0, padding: 0 }}>Response Rate</h5>
          </div>
        </div>
      </div>
    </>
  );
};

MainDashboard.propTypes = {
  TotalAmount: PropTypes.string,
  TotalAmountCurrentMonth: PropTypes.string,
  CurrentMonthName: PropTypes.string,
  prevMonthName: PropTypes.string,
  TotalAmountPrevMonth: PropTypes.string,
  prevSecondMonthName: PropTypes.string,
  TotalAmountSecondPrevMonth: PropTypes.string,
  pendingBookings: PropTypes.number,
  totalRentals: PropTypes.number,
  CurrentMonthArrayChart: PropTypes.array,
  PrevMonthArrayChart: PropTypes.array,
  prevSecondMonthArrayChart: PropTypes.array,
};

export default MainDashboard;

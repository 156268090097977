import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { array, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import {
  calculateAverageUserReview,
  ensureListing,
  getExternalUserReviews,
} from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { IconCollection, NamedLink, ResponsiveImage } from '../../components';
import lighting from '../../assets/ListingCard/lighting.png';

import css from './ListingCard.module.css';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getDistanceFromLocation } from '../../containers/SearchPage/SearchPage.duck';
import { fetchReviewsLite } from '../../containers/ListingPage/ListingPage.duck';
import Skeleton from 'react-loading-skeleton';
import '../../assets/react-loading-skeleton.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
export const LazyImage = lazyLoadWithDimensions(ListingImage, {
  loadAfterInitialRendering: 3000,
});

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    currentUser,
    bookmarkedData,
    isAuthenticated,
    viewport,
    urlQueryParams,
  } = props;
  const [reviews, setreviwes] = useState(null);

  const isMobile = viewport && viewport.width < 767;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const dispatch = useDispatch();
  const isInstantBooking =
    currentListing?.attributes?.publicData?.instantBooking;
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const address = publicData?.location?.address
    ? publicData?.location?.address
    : '500 Bedford Avenue, Brooklyn, New York 11249, United States';
  const sixHoursRental = publicData?.sixHoursRental;

  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0
      ? currentListing.images[0]
      : null;
  const { formattedPrice, priceTitle } = priceData(price, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perDay';

  const bookmark = currentUser?.attributes?.profile.publicData?.bookmark || [];
  const index = bookmark.findIndex(ids => ids === id);
  const handleClick = e => {
    e.preventDefault();
    index > -1
      ? [
          bookmark.splice(index, 1),
          bookmarkedData({ publicData: { bookmark } }),
        ]
      : [bookmark.push(id), bookmarkedData({ publicData: { bookmark } })];
  };

  const bounds = {
    southwest: {
      lat: urlQueryParams?.bounds?.sw?.lat,
      lng: urlQueryParams?.bounds?.sw?.lng,
    },
    northeast: {
      lat: urlQueryParams?.bounds?.ne?.lat,
      lng: urlQueryParams?.bounds?.ne?.lng,
    },
  };

  const searchLat = (bounds.southwest.lat + bounds.northeast.lat) / 2;
  const searchLng = (bounds.southwest.lng + bounds.northeast.lng) / 2;

  const { lat, lng } =
    (listing && listing.id && listing.attributes.geolocation) || {};
  const searchLatLng = urlQueryParams?.bounds
    ? [searchLat, searchLng]
    : [
        currentUser?.attributes?.profile?.protectedData?.origin?.lat,
        currentUser?.attributes?.profile?.protectedData?.origin?.lng,
      ];
  const distance = getDistanceFromLocation(searchLatLng, [lat, lng]);

  useEffect(() => {
    dispatch(fetchReviewsLite(currentListing.author.id)).then(r => {
      setreviwes(r?.data?.data);
    });
  }, []);

  // Retrieve external reviews for the author.
  const authorExternalReviews = getExternalUserReviews(currentListing.author);

  // Calculate the average review considering both local and external reviews for the user.
  const averageReview = calculateAverageUserReview(
    reviews,
    authorExternalReviews
  );

  const splitted = address && address.split(',');
  const stateName =
    splitted && splitted.length == 4
      ? splitted[2]
      : splitted && splitted.length == 5
      ? splitted[3]
      : splitted[1];
  const cityName =
    splitted && splitted.length == 4
      ? splitted[1]
      : splitted && splitted.length == 5
      ? splitted[2]
      : splitted[0];
  const stateName1 = stateName?.split(' ')[1] + ' ' + stateName?.split(' ')[2];
  const finalState =
    stateName?.split(' ').length == 4 ? stateName1 : stateName?.split(' ')[1];

  return (
    <NamedLink
      className={classes}
      name="ListingPage"
      params={{ id, slug }}
      query={{ lat: searchLat, lng: searchLng }}
    >
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        <div className={css.infoContent}>
          <div className={css.mainInfo}>
            <h4 className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </h4>
            <div className={css.ratingStar}>
              {isMobile ? (
                <IconCollection name="RATING_STAR_MOBILE" />
              ) : (
                <IconCollection name="RATING_STAR" />
              )}

              <h6>{averageReview}</h6>
            </div>
          </div>
          <div className={css.locationContent}>
            {isMobile ? (
              <IconCollection name="LOCATION_POINT_MOBILE" />
            ) : (
              <IconCollection name="LOCATION_POINT" />
            )}

            <p>
              {cityName}, {finalState}
            </p>
            {distance ? (
              <h6>
                (
                {distance.toFixed(0) <= 10
                  ? distance.toFixed(1)
                  : distance.toFixed(0)}{' '}
                mi)
              </h6>
            ) : (
              <Skeleton width={50} height={10} />
            )}
          </div>
          <div className={css.price}>
            <div className={css.priceContainer}>
              <div className={css.priceContent}>
                {isInstantBooking == 'instantBooking' && (
                  <img
                    src={lighting}
                    title="Instant booking. Book without waiting for owner approval!"
                    alt=""
                  />
                )}
                <div
                  className={classNames(
                    css.textContainer,
                    sixHoursRental && css.textContainer2
                  )}
                >
                  <h5 className={css.priceValue} title={priceTitle}>
                    {formattedPrice}
                  </h5>
                  <p className={css.perUnit}>
                    <FormattedMessage id={unitTranslationKey} />
                  </p>
                </div>
              </div>
            </div>

            {isAuthenticated && (
              <span onClick={e => handleClick(e)}>
                {index > -1 ? (
                  <IconCollection name="FILLED_LOVE_ICON" />
                ) : (
                  <IconCollection name="LOVE_ICON" />
                )}
              </span>
            )}
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  return {
    currentUser,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  bookmarkedData: id => dispatch(updateProfile(id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  injectIntl
)(ListingCardComponent);

import React from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
// import Collapsible from 'react-collapsible';
// import { FormattedMessage } from '../../util/reactIntl';
import {
  NamedLink,
  IconCollection,
  IconAmenities,
  // AvatarMedium,
  // IconAmenities,
} from '..';

import css from './DashboardNavBar.module.css';

const UserNavBar = props => {
  const {
    // className,
    // rootClassName,
    // selectedPageName,
    // listing,
    // currentUser,
    params,
  } = props;

  // const classes = classNames(rootClassName || css.root, className);
  // const downArrow = <IconCollection name="ARROW_dOWN" />;
  // const arrowTop = <IconCollection name="ARROW_TOP" />;

  const myData = [
    {
      // note : the collapsibleTitle and the type we pass for i.e <IconAmenities type=""/> will be same .
      childContent: [
        { icon: 'd_dashboard', name: 'DashboardPage', label: 'Dashboard' },
        {
          icon: 'd_listings',
          name: 'DashboardListingsPage',
          label: 'My Listings',
        },
        {
          icon: 'd_inbox',
          name: 'MessageListPage',
          label: 'Inbox',
        },
        {
          icon: 'd_booking',
          name: 'BookingRequestPage',
          label: 'Bookings Requests',
        },
        {
          icon: 'd_reservation',
          name: 'ReservationsPage',
          label: 'Reservations',
        },
        // { name: 'SettingPage', label: 'Settings' },
      ],
    },
  ];

  const userNavBarData =
    myData &&
    myData.length &&
    myData.map((ele, i) => {
      return ele.childContent.map((paramsData, i) => {
        const { name, params, label } = paramsData;
        return (
          <div className={css.mainMenu} key={name + i}>
            <NamedLink name={name} params={params || {}} key={i}>
              <div className={css.iconMain}>
                <span className={css.icon}>
                  <IconAmenities type={paramsData.icon} />
                </span>
                <div className={css.collapsibleLink}>
                  <h4>
                    {label}
                    <IconCollection name="ARROW_USER_NAV" />
                  </h4>
                </div>
              </div>
            </NamedLink>
          </div>
        );
      });
    });

  return userNavBarData;
};

UserNavBar.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNavBar.propTypes = {
  className: string,
  rootClassName: string,
  // selectedPageName: string.isRequired,
};

export default UserNavBar;

//Checking if identity check is "do it later" on signup flow

export const getDoLater = currentUser => {
  return (
    currentUser &&
    currentUser.id &&
    currentUser?.attributes?.profile?.publicData?.doLater
  );
};

export const getVerificationStatus = (currentUser) => {
  return currentUser && currentUser.id && 
  ((currentUser?.attributes?.profile?.protectedData && currentUser?.attributes?.profile?.protectedData?.verification_status) 
  || !!currentUser?.attributes?.profile?.publicData?.website);
}
// Return captilized string
export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

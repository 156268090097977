import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldCheckbox,
  FieldSelect,
  IconAmenities,
} from '../../components';

import { Link } from 'react-router-dom';

import css from './SignupForm.module.css';
import { hearAboutUs } from '../../marketplace-custom-config';
import LocationForm from '../LocationForm/LocationForm';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [showError, setshowError] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <FinalForm
        {...props}
        validateOnBlur={false}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            formId,
            handleSubmit,
            inProgress,
            invalid,
            intl,
            values,
            form,
            onOpenTermsOfService,
          } = fieldRenderProps;

          // email
          const emailLabel = intl.formatMessage({
            id: 'SignupForm.emailLabel',
          });
          const emailRequiredMessage = intl.formatMessage({
            id: 'SignupForm.emailRequired',
          });
          const emailRequired = validators.required(emailRequiredMessage);
          const emailInvalidMessage = intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          });
          const emailValid = validators.emailFormatValid(emailInvalidMessage);

          // password
          const passwordLabel = intl.formatMessage({
            id: 'SignupForm.passwordLabel',
          });
          const passwordRequiredMessage = intl.formatMessage({
            id: 'SignupForm.passwordRequired',
          });
          const passwordMinLengthMessage = intl.formatMessage(
            {
              id: 'SignupForm.passwordTooShort',
            },
            {
              minLength: validators.PASSWORD_MIN_LENGTH,
            }
          );
          const passwordMaxLengthMessage = intl.formatMessage(
            {
              id: 'SignupForm.passwordTooLong',
            },
            {
              maxLength: validators.PASSWORD_MAX_LENGTH,
            }
          );
          const passwordMinLength = validators.minLength(
            passwordMinLengthMessage,
            validators.PASSWORD_MIN_LENGTH
          );
          const passwordMaxLength = validators.maxLength(
            passwordMaxLengthMessage,
            validators.PASSWORD_MAX_LENGTH
          );
          const passwordRequired = validators.requiredStringNoTrim(
            passwordRequiredMessage
          );
          const passwordValidators = validators.composeValidators(
            passwordRequired,
            passwordMinLength,
            passwordMaxLength
          );

          // firstName
          const firstNameLabel = intl.formatMessage({
            id: 'SignupForm.firstNameLabel',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'SignupForm.firstNameRequired',
          });

          const nameInvalidMessage = intl.formatMessage({
            id: 'SignupForm.nameInvalid',
          });
          const firstNameRequired = validators.required(
            firstNameRequiredMessage
          );
          const firstNameValid = validators.nameFormatValid(nameInvalidMessage);

          // hearAboutUs
          const hearAboutUsLabel = intl.formatMessage({
            id: 'SignupForm.hearAboutUsLabel',
          });
          const hearAboutUsPlaceholder = intl.formatMessage({
            id: 'SignupForm.hearAboutUsPlaceholder',
          });

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress || !values.policy;

          const handleTermsKeyUp = e => {
            // Allow click action with keyboard like with normal links
            if (e.keyCode === KEY_CODE_ENTER) {
              onOpenTermsOfService();
            }
          };
          const termsLink = (
            <>
              {' '}
              <Link
                className={css.termsLink}
                to="/terms-of-service"
                role="button"
                tabIndex="0"
                onKeyUp={handleTermsKeyUp}
              >
                <FormattedMessage id="SignupForm.termsAndConditionsLinkTextNew" />
              </Link>{' '}
              &{' '}
              <Link
                className={css.termsLink}
                to="/privacy-policy"
                role="button"
                tabIndex="0"
                onKeyUp={handleTermsKeyUp}
              >
                <FormattedMessage id="SignupForm.privacyPolicyLinkText" />
              </Link>
            </>
          );

          const scoreWithOr = <IconAmenities type="orSignUp" />;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.switchBusinessButtons}>
                <Link
                  to="/signup"
                  className={`${css.swithButtonClicked} ${css.switchButton}`}
                >
                  Personal
                </Link>
                <Link to="/signup/business" className={`${css.switchButton}`}>
                  Business
                </Link>
              </div>

              <div>
                <div className={css.name}>
                  <FieldTextInput
                    className={css.NameRoot}
                    type="text"
                    id={formId ? `${formId}.fname` : 'fname'}
                    name="fname"
                    autoComplete="given-name"
                    label={firstNameLabel}
                    // placeholder={firstNamePlaceholder}
                    validate={validators.composeValidators(
                      firstNameRequired,
                      firstNameValid
                    )}
                    formatOnBlur={true}
                    format={value => value?.trim()}
                  />
                </div>

                <FieldTextInput
                  type="email"
                  initialValue={localStorage.getItem('email')}
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  className={css.NameRoot}
                  label={emailLabel}
                  // placeholder={emailPlaceholder}
                  validate={validators.composeValidators(
                    emailRequired,
                    emailValid
                  )}
                />
              </div>

              <div className={css.passwordContainer}>
                <FieldTextInput
                  className={css.password}
                  type={passwordShown ? 'text' : 'password'}
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="new-password"
                  label={passwordLabel}
                  // placeholder={passwordPlaceholder}
                  validate={passwordValidators}
                />
                {passwordShown ? (
                  <span
                    className={css.passwordShowHide}
                    onClick={togglePassword}
                  >
                    <IconAmenities type="openEye" />
                  </span>
                ) : (
                  <span
                    className={css.passwordShowHide}
                    onClick={togglePassword}
                  >
                    <IconAmenities type="closeEye" />
                  </span>
                )}
              </div>

              <LocationForm
                formId={formId}
                form={form}
                values={values}
                intl={intl}
                setshowError={setshowError}
              />
              {showError ? (
                <p className={css.errorText}>
                  <FormattedMessage id="SignupForm.zipcodeValidation" />
                </p>
              ) : null}

              <FieldSelect
                id={formId ? `${formId}.hearAboutUs` : 'hearAboutUs'}
                name="hearAboutUs"
                className={css.field}
                label={hearAboutUsLabel}
                validate={validators.autocompleteSearchRequired(
                  'Please select an option'
                )}
              >
                <option value="">{hearAboutUsPlaceholder}</option>
                {hearAboutUs.map(item => {
                  return (
                    <option key={item.key} value={item.value}>
                      {item.value}
                    </option>
                  );
                })}
              </FieldSelect>

              <div className={css.bottomWrapper}>
                <p className={css.bottomWrapperText}>
                  <FieldCheckbox id="policy" name="policy" />
                  <span className={css.termsText}>
                    <FormattedMessage
                      id="SignupForm.termsAndConditionsAcceptText"
                      values={{ termsLink }}
                    />
                  </span>
                </p>
                <PrimaryButton
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled || showError}
                >
                  <FormattedMessage id="SignupForm.signUp" />
                </PrimaryButton>
                <h6>
                  {scoreWithOr}
                  <FormattedMessage id="SignupForm.or" />
                  {scoreWithOr}
                </h6>
              </div>
            </Form>
          );
        }}
      />
    </>
  );
};

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;

import {
  AvatarLarge,
  Footer,
  IconAmenities,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  ListingCard,
  NamedLink,
  Page,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './BusinessProfileOthers.module.css';

import { compose } from 'redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import Dropdown from 'react-dropdown';
import { displayCategories } from '../../marketplace-custom-config';
import { useEffect, useMemo, useState } from 'react';
import "./Dropdown.style.css";
import { REVIEW_TYPE_OF_PROVIDER } from '../../util/types';
import { calculateAverageUserReview, totalUserReviewCount } from '../../util/data';
import ProfileReviews from '../ProfilePage/ProfileReviews';
import { ensureURL } from '../BusinessProfileSelf/BusinessProfileSelf';

const BusinessProfile = (props) => {
  const {
    listings,
    user,
    reviews,
    currentUser,
    location,
  } = props;
  const [showReviews, setShowReviews] = useState(false);
  const { hash } = location;
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');
  const publicData = user?.attributes?.profile?.publicData;
  const filterOptions = useMemo(() => {
    if (!listings) {
      return null;
    }
    const categoryToNumberOfListings = listings.reduce((acc, listing) => {
      const category = listing.attributes.publicData.category;
      return {
        ...acc,
        [category]: acc[category] ? acc[category] + 1 : 1,
      };
    }, {});
    return [
      { label: `All Product (${listings?.length || 0})`, value: '' },
      ...Object.keys(categoryToNumberOfListings).map(category => ({
        label: `${displayCategories.find(c => c.key.toLowerCase() === category.toLowerCase())?.value} (${categoryToNumberOfListings[category]})`,
        value: category,
      }))
    ];
  });
  const [selectedCategory, setSelectedCategory] = useState('');
  const reviewsOfProvider = reviews.filter(
    r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER
  );
  const averageProviderReview = calculateAverageUserReview(reviewsOfProvider, []);
  const reviewCountProvider = totalUserReviewCount(reviewsOfProvider.length, []);

  useEffect(() => {
    if (hash === '#reviews') {
      setShowReviews(true);
    }
    else {
      setShowReviews(false);
    }
  }, [hash]);

  if (!user || !listings || !filterOptions) {
    return null;
  }
  return (
    <div>
      {/* Header */}
      <div className={css.profileHeader}>
        <div>
          <AvatarLarge
            className={css.profilePic}
            user={user}
          />
        </div>
        <div>
          <h3 className={css.profileTitle}>{publicData?.businessName}</h3>
          <div className={css.ratingBox}>
            <IconAmenities type="star" />
            <span>{averageProviderReview} (<a href='#reviews' style={{ color: '#000', textDecoration: 'underline' }}>{reviewCountProvider}</a>)</span>
          </div>
          <div className={css.verifiedBox}>
            <span className={css.verifiedIcon}>
              <IconAmenities type="verified_icon" />
              <span>Verified Business</span>
            </span>
            <span>
              <NamedLink
                name="MessagePage"
                params={{ id: user?.id?.uuid }}
                rel="nofollow"
              >
                <IconAmenities type="chat" />
              </NamedLink>
            </span>
          </div>
          <div className={css.social_icons}>
            {!!publicData?.website && <a href={ensureURL(publicData?.website)} target="_blank">
              <IconAmenities type="website_gray" />
            </a>}

            {!!publicData?.zipLocation && <a href={`https://www.google.com/maps/search/?api=1&query=${publicData?.zipLocation}`} target="_blank">
              <IconAmenities type="location_gray" />
            </a>}

            {publicData?.facebook && <a href={ensureURL(publicData?.facebook)} target="_blank">
              <IconAmenities type="facebook_gray" />
            </a>}

            {!!publicData?.instagram && <a href={ensureURL(publicData?.instagram)} target="_blank">
              <IconAmenities type="instagram_gray" />
            </a>}

            {!!publicData?.linkedIn && <a href={ensureURL(publicData?.linkedIn)} target="_blank">
              <IconAmenities type="linkedin_gray" />
            </a>}

            {!!publicData?.twitter && <a href={ensureURL(publicData?.twitter)} target="_blank">
              <IconAmenities type="twitter_gray" />
            </a>}
          </div>
        </div>
      </div>

      {/* Filter */}
      {!showReviews && <div>
        <button class={css.filter_box}>
          <Dropdown
            className={css.filter_dropdown}
            options={filterOptions}
            onChange={(option) => setSelectedCategory(option.value)}
            value={selectedCategory}
            placeholder="Select an option"
            arrowOpen={<IconAmenities type="arrow_up" />}
            arrowClosed={<IconAmenities type="arrow_down" />}
            controlClassName={css.Dropdown_control}
            menuClassName={css.Dropdown_menu}
          />
        </button>
      </div>}

      {/* Products */}
      {!showReviews && <div className={css.products_box}>
        {!!listings && listings
          .filter(listing => !selectedCategory || listing.attributes.publicData.category === selectedCategory)
          .map((l) => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              reviews={reviews}
              currentUser={currentUser}
            />))}
      </div>}

      {!!showReviews && <ProfileReviews reviews={reviews} user={currentUser} isCurrentUser={false} />}

    </div>
  );
};

const BusinessProfileOthersPage = (props) => {
  return (
    <Page>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.root}>
            <BusinessProfile {...props} />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;
  const {
    userId,
    userListingRefs,
    reviews,
    queryListingsInProgress,
  } = state.ProfilePage;
  const currentUserListingsId = userListingRefs.map(l => {
    return l.id;
  });
  const listings = getListingsById(state, currentUserListingsId);
  const userMatches = getMarketplaceEntities(state, [
    { type: 'user', id: userId },
  ]);
  const user = userMatches.length === 1 ? userMatches[0] : null;
  return {
    listings: queryListingsInProgress ? null : listings,
    user,
    reviews,
    currentUser
  };
};

const mapDispatchToProps = (dispatch) => ({});

const BusinessProfileOthers = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(BusinessProfileOthersPage);

export default BusinessProfileOthers;

import classNames from 'classnames';
import { array, bool, func, object, string } from 'prop-types';
import React, { Component } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { compose } from 'redux';
import {
  Form,
  IconCollection,
  IconSpinner,
  InlineTextButton,
  PrimaryButton,
} from '../../components';
import config from '../../config';
import { timestampToDate } from '../../util/dates';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';

import { truncate } from 'lodash';
import moment from 'moment';
import SectionMapMaybe from '../../containers/ListingPage/SectionMapMaybe';
import css from './BookingTimeForm.module.css';

const BIO_COLLAPSED_LENGTH = 170;
const truncated = s => {
  return truncate(s, {
    length: BIO_COLLAPSED_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

class ExpandableBio extends Component {
  constructor(props) {
    super(props);
    this.state = { expand: false };
  }
  render() {
    const { expand } = this.state;
    const { className, bio } = this.props;
    const truncatedBio = truncated(bio);

    const handleShowMoreClick = () => {
      this.setState({ expand: true });
    };
    const handleShowLessClick = () => {
      this.setState({ expand: false });
    };
    const showMore = (
      <InlineTextButton
        rootClassName={css.showMore}
        onClick={handleShowMoreClick}
      >
        <FormattedMessage id="UserCard.showFullBioLink" />
      </InlineTextButton>
    );
    const showLess = (
      <InlineTextButton
        rootClassName={css.showMore}
        onClick={handleShowLessClick}
      >
        <FormattedMessage id="UserCard.showLessBioLink" />
      </InlineTextButton>
    );
    return (
      <p className={className}>
        {expand ? bio : truncatedBio}
        {bio !== truncatedBio && !expand
          ? showMore
          : expand == false
            ? null
            : showLess}
      </p>
    );
  }
}

ExpandableBio.defaultProps = { className: null };

ExpandableBio.propTypes = {
  className: string,
  bio: string.isRequired,
};

const WAIT_MS = 100;
export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const {
      bookingStartTime,
      bookingEndTime,
      selectedTime,
      diffBetweenDays,
      delivery,
    } = formValues.values;

    const startDate = bookingStartTime
      ? timestampToDate(bookingStartTime)
      : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

    const nextDate = moment(startDate)
      .add(24, 'hours')
      .toDate();

    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    // We expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form

    if (
      selectedTime == 'sixHours' ||
      (selectedTime == 'day' && diffBetweenDays <= 1)
    ) {
      if (
        bookingStartTime &&
        bookingEndTime &&
        !this.props.fetchLineItemsInProgress
      ) {
        this.props.onFetchTransactionLineItems({
          bookingData: {
            startDate,
            endDate: selectedTime == 'day' ? nextDate : endDate,
            selectedTime: selectedTime ? selectedTime : 'sixHours',
            delivery: delivery,
          },
          listingId,
          isOwnListing,
        });
      }
    } else {
      if (
        bookingStartTime &&
        bookingEndTime &&
        diffBetweenDays > 2 &&
        !this.props.fetchLineItemsInProgress
      ) {
        this.props.onFetchTransactionLineItems({
          bookingData: {
            startDate: formValues.values?.bookingStartDate?.date,
            endDate: formValues.values?.bookingEndDate?.date,
            selectedTime: selectedTime ? selectedTime : 'sixHours',
            delivery: delivery,
          },
          listingId,
          isOwnListing,
        });
      }
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }

    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            isOwnListin
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            geolocation,
            publicData,
            isMobile,
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            invalid,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            timeSlots,
            listing,
            distanceInMiles,
            isBook,
            timeValue,
            onManageDisableScrolling,
            isSelectedPrice,
            isModalOpen,
            handleModal,
            handleSelectDayTime,
            handleSelectMultiTime,
            handleSelectTime,
            duration,
            instantBooking,
          } = fieldRenderProps;
          // SETTING UP THE INTITAL DATES
          const initialDate = values?.bookingStartTime
            ? values?.bookingStartTime
            : values?.bookingStartDate?.date;
          const eDate = values?.bookingEndDate?.date;
          var dateArray = [];
          var currentDate = moment(initialDate);
          var stopDate = moment(eDate);
          while (currentDate <= stopDate) {
            dateArray.push(moment(currentDate));
            currentDate = moment(currentDate).add(1, 'days');
          }
          const filteredBookingDate = dateArray.map(st => (
            !!timeSlots && timeSlots.some((ts) => moment(st).isBetween(moment(ts.attributes.start), moment(ts.attributes.end), 'dates', '[]'))
          ));
          const availableDate = filteredBookingDate.every(st => st == true);
          const link = publicData?.storeLink;
          const urlLink =
            link && link.split(':')[0] === 'https'
              ? link
              : 'https:'//${link};
          const price = listing.attributes.price;
          const startTime =
            values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime =
            values && values.bookingEndTime ? values.bookingEndTime : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });
          const diffBetweenDays = moment(values?.bookingEndDate).diff(
            values?.bookingStartDate,
            'days'
          );

          if (Number.isInteger(diffBetweenDays)) {
            form.change('diffBetweenDays', diffBetweenDays);
          }
          if (timeValue) {
            form.change('selectedTime', timeValue);
          }
          if (!values.bookingStartDate) {
            values.bookingStartDate = moment();
            values.bookingEndDate = moment();
            if (values.bookingStartDate.hour() >= 23) {
              values.bookingStartDate = values.bookingStartDate.add(1, 'days');
              values.bookingEndDate = values.bookingEndDate.add(1, 'days');
            }
            values.bookingDates = {startDate: new Date(values.bookingStartDate), endDate: new Date(values.bookingEndDate)};
          }
          setTimeout(() => {
            const timeValue = values.selectedTime;
            if (!values.bookingEndDate || !values.bookingDates.endDate) return;
            if (timeValue === 'sixHours' && values.bookingStartDate.hour() > 17) {
              values.bookingStartDate = values.bookingStartDate.add(1, 'days').startOf('day').add(8, 'hours');
              form.change("bookingStartDate", moment(values.bookingStartDate));
            }

            if (timeValue === 'day' && ![1,2].includes(moment(values.bookingEndDate).diff(values.bookingStartDate, 'days'))) {
              form.change("bookingEndDate", moment(values.bookingStartDate).add(1, 'days'));
              values.bookingDates.endDate = new Date(moment(values.bookingStartDate).add(1, 'days'));
            }
            else if (timeValue === '1' && moment(values.bookingEndDate).diff(values.bookingStartDate, 'days') < 3) {
              form.change("bookingEndDate", moment(values.bookingStartDate).add(3, 'days'));
              values.bookingDates.endDate = new Date(moment(values.bookingStartDate).add(3, 'days'));
            }
            else if (timeValue === "sixHours" && moment(values.bookingEndDate).diff(values.bookingStartDate, 'days', true) != 0){
              form.change("bookingEndDate", moment(values.bookingStartDate));
              values.bookingDates.endDate = new Date(moment(values.bookingStartDate));
            }
            values.bookingDates = {startDate: new Date(values.bookingStartDate), endDate: new Date(values.bookingEndDate)};
          }, 100);

          if (isSelectedPrice) {
            form.change(
              'selectedPrice',
              timeValue == 'sixHours'
                ? Math.round((price.amount * 60) / 10000)
                : // : timeValue == "day" ? price.amount / 100
                timeValue == 'day' && diffBetweenDays <= 1
                  ? price.amount / 100
                  : timeValue == 'day' &&
                    diffBetweenDays > 1 &&
                    diffBetweenDays < 3
                    ? price.amount / 100
                    : timeValue == 'day' &&
                      diffBetweenDays >= 3 &&
                      !publicData.multiDayRental
                      ? price.amount / 100
                      : Math.round((price.amount * 70) / 10000)
            );
          }

          const startDate = startTime ? timestampToDate(startTime) : null;

          // const endDate = endTime ? timestampToDate(timeValue == "day" ? startTime : endTime) : null;
          const endDate = endTime
            ? timestampToDate(
              timeValue == 'day' && diffBetweenDays <= 1 ? startTime : endTime
            )
            : null;

          const nextDate = moment(startDate)
            .add(diffBetweenDays * 24, 'hours')
            .toDate();
          const nextToStart = moment(startDate)
            .add(diffBetweenDays * 24, 'hours')
            .toDate();
          const nextOneDate = moment(startDate)
            .add(24, 'hours')
            .toDate();

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData = {
            unitType,
            startDate: startDate,
            // endDate: timeValue == 'day' ? nextOneDate : timeValue == "1" ? nextToStart : endDate,
            endDate:
              timeValue == 'day' && diffBetweenDays <= 1
                ? nextOneDate
                : timeValue == '1' ||
                  (timeValue == 'day' && diffBetweenDays >= 3)
                  ? nextToStart
                  : endDate,
            timeZone,
            nextDate,
          };

          const showEstimatedBreakdown =
            bookingData &&
            lineItems &&
            !fetchLineItemsInProgress &&
            !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              {isMobile ? null : (
                <h3 className={css.priceBreakdownTitle}>
                  <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
                </h3>
              )}
              {isMobile ? null : (
                <EstimatedBreakdownMaybe
                  bookingData={bookingData}
                  lineItems={lineItems}
                  selectedTime={timeValue}
                  diffBetweenDays={diffBetweenDays}
                />
              )}
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingTimeForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const isSelectedDatesAvailable = (bookingStartTime, bookingEndTime) => {
            const start = moment(parseInt(bookingStartTime));
            const end = moment(parseInt(bookingEndTime));
            if (values.selectedTime === 'sixHours' && start.hour() >= 18) {
              return false;
            }
            return timeSlots && timeSlots.some((ts) => (
              start.isBetween(moment(ts.attributes.start), moment(ts.attributes.end), 'dates', '[]') 
              && end.isBetween(moment(ts.attributes.start), moment(ts.attributes.end), 'dates', '[]')
            ));
          }

          return (
            <Form
              className={classNames(classes, css.bookingPanelMobile)}
              onSubmit={handleSubmit}
              enforcePagePreloadFor="CheckoutPage"
            >
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  !isMobile && this.handleOnChange(values);
                }}
              />
              {monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  onManageDisableScrolling={onManageDisableScrolling}
                  availableDate={availableDate}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  isBook={isBook}
                  isMobile={isMobile}
                  intl={intl}
                  form={form}
                  listing={listing}
                  unitType={unitType}
                  isOwnListing={isOwnListing}
                  pristine={pristine}
                  timeZone={timeZone}
                  selectedTime={timeValue ? timeValue : null}
                  timeSlots={timeSlots}
                  diffBetweenDays={diffBetweenDays}
                  isModalOpen={isModalOpen}
                  handleModal={handleModal}
                  handleSelectDayTime={handleSelectDayTime}
                  handleSelectMultiTime={handleSelectMultiTime}
                  handleSelectTime={handleSelectTime}
                  duration={duration}
                  instantBooking={instantBooking}
                />
              ) : null}

              {isOwnListing || (isModalOpen && isMobile) ? null : (
                <>
                  {isBook ? (
                    <>
                      {(!availableDate && diffBetweenDays > 2) ||
                        (!availableDate &&
                          diffBetweenDays == 1 &&
                          timeValue == 'day') ? (
                        <p className={css.error}>
                          Please recheck your selected dates
                        </p>
                      ) : (diffBetweenDays > 2 ||
                        timeValue == 'sixHours' ||
                        timeValue == 'day') &&
                        availableDate ? (
                        bookingInfoMaybe
                      ) : (diffBetweenDays == 2 ||
                        diffBetweenDays == 1 ||
                        diffBetweenDays == 0) &&
                        timeValue == '1' &&
                        values.bookingStartDate ? (
                        <p className={css.error}>
                          Please select three days or more than three days
                        </p>
                      ) : null}
                      {!values?.bookingStartDate && values?.bookingEndDate ? (
                        <p className={css.error}>Please select start time and end time</p>
                      ) : null}
                      {!isSelectedDatesAvailable(values?.bookingStartTime, values?.bookingEndTime) ? (
                        <p className={css.error}>Item not available for selected time</p>
                      ) : null}
                      {loadingSpinnerMaybe}
                      {!values?.bookingStartDate ? null : bookingInfoErrorMaybe}
                    </>
                  ) : (
                    <>
                      {!availableDate && diffBetweenDays > 2 ? (
                        <p className={css.error}>
                          Please recheck your selected dates
                        </p>
                      ) : diffBetweenDays > 2 ||
                        timeValue == 'sixHours' ||
                        timeValue == 'day' ? (
                        bookingInfoMaybe
                      ) : diffBetweenDays == 2 ||
                        diffBetweenDays == 1 ||
                        (diffBetweenDays == 0 &&
                          timeValue == '1' &&
                          values.bookingStartDate) ? (
                        <p className={css.error}>
                          Please select three days or more than three days
                        </p>
                      ) : null}
                      {!values?.bookingStartDate && values?.bookingEndDate ? (
                        <p className={css.error}>Please select start time and end time</p>
                      ) : null}
                      {!isSelectedDatesAvailable(values?.bookingStartTime, values?.bookingEndTime) ? (
                        <p className={css.error}>Item not available for selected time</p>
                      ) : null}

                      {loadingSpinnerMaybe}
                      {!values.bookingStartDate ? null : bookingInfoErrorMaybe}
                      <div className={css.learnMoreSection}>
                        <div className={css.freeCancellation}>
                          <IconCollection name="THUMPS_UP" />
                          <h6>Free Cancellation</h6>
                        </div>
                        <div className={css.learnMoreSectionLink}>
                          Coming Soon
                        </div>
                      </div>
                      <div className={css.officialBrandSection}>
                        <div className={css.officialBrand}>
                          <IconCollection name="BRAND_STORE" />
                          <h6>Item on official brand store</h6>
                        </div>
                        <a href={urlLink} target="_blank">
                          <IconCollection name="BRAND_STORE_LINK" />
                        </a>
                      </div>
                    </>
                  )}

                  <SectionMapMaybe
                    geolocation={geolocation}
                    publicData={publicData}
                    listingId={listingId}
                    distanceInMiles={distanceInMiles}
                  />

                  <div
                    className={classNames(
                      submitButtonClasses,
                      css.submitButtonWrapper
                    )}
                  >
                    <div>
                      <span className={css.priceText}>
                        $
                        {diffBetweenDays === 0
                          ? Math.round((price.amount * 60) / 10000)
                          : diffBetweenDays === 1
                            ? Math.round(price.amount / 100)
                            : diffBetweenDays > 1 && diffBetweenDays < 3
                              ? Math.round(price.amount / 100)
                              : diffBetweenDays >= 3 && publicData.multiDayRental
                                ? Math.round((price.amount * 70) / 10000)
                                : diffBetweenDays >= 3 && !publicData.multiDayRental
                                  ? Math.round(price.amount / 100)
                                  : Math.round((price.amount * 70) / 10000)}
                      </span>{' '}
                      <span className={css.hourText}>
                        {diffBetweenDays === 0
                          ? 'Six hours'
                          : timeValue == 'day' && diffBetweenDays === 1
                            ? 'Day'
                            : diffBetweenDays
                              ? 'Day'
                              : null}
                      </span>
                    </div>
                    <PrimaryButton
                      disabled={
                        !values?.bookingStartDate || !values?.bookingEndDate || !values?.bookingStartTime || !availableDate 
                        || (diffBetweenDays === 0 && !publicData?.sixHoursRental) 
                        || !isSelectedDatesAvailable(values?.bookingStartTime, values?.bookingEndTime)
                      }
                    >
                      <FormattedMessage id="BookingTimeForm.requestToBook" />
                    </PrimaryButton>
                  </div>
                </>
              )}
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
import React from 'react';
import css from './MessagePage.module.css';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useConversation, useConversations } from '../../firebase';
import { showUser } from '../ProfilePage/ProfilePage.duck';
import { Avatar, LayoutSingleColumn, LayoutWrapperMain, LayoutWrapperTopbar, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { getFirebaseToken, sendEmailMessages } from '../../util/api';

const MessagePageComponent = (props) => {
    const { currentUser, params, getUser } = props;
    const currentUserId = currentUser?.id?.uuid;
    const receiverId = params.id.replace(":", "").replace('#', '');
    const [firebaseToken, setFirebaseToken] = React.useState(null);
    const [foundConversations, isLoadingConversations] = useConversations(currentUserId || null, receiverId || null);
    const [conversation, updateConversation, , isLoadingConversation] = useConversation(foundConversations?.[0]?.id || null, firebaseToken);
    const [receiver, setReceiver] = React.useState(null);
    const addMessage = (text) => {
        if (!text || text.trim().length === 0) return;
        const message = {
            text,
            senderId: currentUserId,
            receiverId,
            timestamp: Date.now(),
            isNew: true
        }

        updateConversation(conversation ? {
            ...conversation,
            messages: [...conversation.messages, message]
        } : {
            participants: [currentUserId, receiverId],
            messages: [message]
        });

        sendEmailMessages({
            conversationId: conversation?.id,
            message: text
        })
    }

    React.useEffect(() => {
        (async () => {
            const result = (await getUser(receiverId))?.data;
            if (result?.data) {
                var user = JSON.parse(JSON.stringify(result.data));
                const profileImage = result?.included?.[0];
                if (profileImage) {
                    user.profileImage = profileImage;
                }
                setReceiver(user);
            }
        })();
    }, [receiverId]);

    React.useEffect(() => {
        if (!conversation && !isLoadingConversation && !!foundConversations && foundConversations.length === 0 && !isLoadingConversations) {
            updateConversation({
                participants: [currentUserId, receiverId],
                messages: []
            })
        }
    }, [foundConversations, isLoadingConversations, conversation, isLoadingConversation]);

    React.useEffect(() => {
        const chatContainer = document.querySelector(`.${css.chatContainer}`);
        if (!chatContainer) return;
        chatContainer.scrollTop = chatContainer.scrollHeight;

        if (conversation && conversation.messages.filter(message => message.isNew && message.senderId !== currentUserId).length > 0) {
            updateConversation({
                ...conversation,
                messages: conversation.messages.map(message => ({
                    ...message,
                    isNew: false
                }))
            })
        }
    }, [conversation]);

    React.useEffect(() => {
        getFirebaseToken().then((response) => {
            setFirebaseToken(response.firebaseToken);
        });
    }, [])

    return (
        <Page
            title="Message"
            scrollingDisabled={true}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer currentPage="AddressPage" />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain>

                    <div className={css.mainContainer}>
                        <div className={css.heading}>
                            <div onClick={() => props.history.goBack()}>
                                {/* <IconBack /> */}
                                <img src="https://img.icons8.com/?size=512&id=84994&format=png" width={36} height={36} />
                            </div>
                            <Avatar user={receiver} />
                            <div>
                                <h3 className={css.title}>{receiver?.attributes?.profile?.displayName}</h3>
                            </div>
                        </div>

                        <div className={css.chatContainer}>
                            {(conversation?.messages || []).map((message, index) => (
                                <>
                                    {index === 0 || new Date(message.timestamp).getDate() !== new Date(conversation.messages[index - 1].timestamp).getDate() ? (
                                        <p className={css.dateText}>
                                            {new Date(message.timestamp).toLocaleDateString([], { weekday: 'long', month: 'long', day: 'numeric' })}
                                        </p>
                                    ) : null}
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: message.senderId === currentUserId ? 'row-reverse' : 'row',
                                        gap: '10px',
                                        alignItems: 'center'
                                    }}>
                                        {/* <Avatar user={message.senderId === currentUserId ? currentUser : receiver} /> */}
                                        <div className={message.senderId === currentUserId ? css.senderMessage : css.receiverMessage} key={message.timestamp}>
                                            <p className={css.messageText}>{message.text}</p>
                                            <p className={css.messageTime}>
                                                {new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                            </p>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>

                    <div className={css.inputContainer}>
                        <input className={css.input} placeholder="Type a message" />
                        <button className={css.sendButton} onClick={() => {
                            addMessage(document.querySelector(`.${css.input}`).value);
                            document.querySelector(`.${css.input}`).value = "";
                        }}>
                            <img src="/static/icons/sendButton.svg" />
                        </button>
                    </div>
                </LayoutWrapperMain>
            </LayoutSingleColumn>
        </Page>


    );
}

const mapStateToProps = state => {
    const { currentUser } = state.user;

    return { currentUser };
}

const mapDispatchToProps = dispatch => ({
    getUser: (userId) => dispatch(showUser(userId))
});

const MessagePage = compose(
    withViewport,
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(MessagePageComponent)

export default MessagePage;

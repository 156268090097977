import StarRatings from 'react-star-ratings';
import { REVIEW_TYPE_OF_CUSTOMER, REVIEW_TYPE_OF_PROVIDER } from '../../util/types';
import { IconCollection, Reviews } from '../../components';
import { calculateAverageUserReview, calculateUserRatingPercentage, ensureUser, totalUserReviewCount } from '../../util/data';
import { useState } from 'react';
import css from './ProfilePage.module.css';
import ProgressBar from '@ramonak/react-progress-bar';
import classNames from 'classnames';

const parseRatingValue = value => {
    // 1 decimal place
    return parseFloat(value).toFixed(1);
};


const ProfileReviews = (props) => {
    const {
        reviews,
        user,
        isCurrentUser,
    } = props;
    const [isCustomer, setIsCustomer] = useState(REVIEW_TYPE_OF_PROVIDER);
    const reviewsOfProvider = reviews.filter(
        r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER
    );
    const reviewsOfCustomer = reviews.filter(
        r => r.attributes.type === REVIEW_TYPE_OF_CUSTOMER
    );
    const averageProviderReview = calculateAverageUserReview(reviewsOfProvider, []);
    const averageCustomerReview = calculateAverageUserReview(reviewsOfCustomer);
    const reviewCountCustomer = totalUserReviewCount(reviewsOfCustomer.length);
    const reviewCountProvider = totalUserReviewCount(reviewsOfProvider.length, []);
    const profileUser = ensureUser(user);
    const ratingPercentageProvider = calculateUserRatingPercentage(reviewsOfProvider);
    const ratingPercentageCustomer = calculateUserRatingPercentage(reviewsOfCustomer);

    return (
        <div className={css.reviewContents}>
            <div className={css.reviewNumber}>
                <h2>
                    {' '}
                    {parseRatingValue(
                        isCustomer === REVIEW_TYPE_OF_CUSTOMER
                            ? averageCustomerReview
                            : isCustomer === REVIEW_TYPE_OF_PROVIDER
                                ? averageProviderReview
                                : 0
                    )}
                </h2>

                <StarRatings
                    svgIconViewBox="0 0 40 37"
                    svgIconPath="M20 0L26.113 11.5862L39.0211 13.8197L29.891 23.2138L31.7557 36.1803L20 30.4L8.2443 36.1803L10.109 23.2138L0.97887 13.8197L13.887 11.5862L20 0Z"
                    starRatedColor="#FFC700"
                    rating={
                        isCustomer === REVIEW_TYPE_OF_CUSTOMER ? Number(averageCustomerReview) : Number(averageProviderReview)
                    }
                    starDimension="40px"
                    starSpacing="8px"
                />
                <p>
                    {isCustomer === REVIEW_TYPE_OF_CUSTOMER ? reviewCountCustomer : reviewCountProvider}{' '}
                    Reviews
                </p>
                <div className={css.progressBarWrapper}>
                    {[...Array(5).keys()].map((i) => (
                        <div className={css.oneStar}>
                            <p>{i + 1}</p>
                            <IconCollection name="REVIEW_STAR" />
                            <ProgressBar
                                borderRadius="2px"
                                bgColor="#FACC16"
                                baseBgColor="#D9D9D9"
                                labelColor="rgba(6, 5, 49, 0.3);"
                                height="16px"
                                completed={
                                    (isCustomer === REVIEW_TYPE_OF_CUSTOMER ? ratingPercentageCustomer[i] : ratingPercentageProvider[i]) || 0
                                }
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div>
                {!!isCurrentUser && <div className={css.reviewTitle}>
                    <h3
                        className={classNames(
                            css.text,
                            isCustomer === REVIEW_TYPE_OF_CUSTOMER ? null : css.activeText
                        )}
                        onClick={() => {
                            setIsCustomer(REVIEW_TYPE_OF_PROVIDER);
                        }}
                    >
                        Renters
                    </h3>
                    ({' '}
                    <div className={css.starReviewTitle}>
                        <IconCollection name="REVIEW_STAR" />
                        <h6>
                            {averageProviderReview}
                            <span className={css.mobileStar}>
                                {' '}
                                ({reviewCountProvider})
                            </span>
                        </h6>
                    </div>
                    )
                    <h3
                        className={classNames(
                            css.text, isCustomer === REVIEW_TYPE_OF_CUSTOMER && css.activeText
                        )}
                        onClick={() => {
                            setIsCustomer(REVIEW_TYPE_OF_CUSTOMER);
                        }}
                    >
                        Lenders
                    </h3>
                    (
                    <div className={css.starReviewTitle}>
                        <IconCollection name="REVIEW_STAR" />
                        <h6>
                            {averageCustomerReview}
                            <span className={css.mobileStar}>
                                {' '}
                                ({reviewCountCustomer})
                            </span>
                        </h6>
                    </div>
                    )
                </div>}
                {isCustomer === REVIEW_TYPE_OF_PROVIDER ? (
                    <Reviews reviews={reviewsOfProvider} user={profileUser} />
                ) : (
                    <Reviews reviews={reviewsOfCustomer} user={profileUser} />
                )}
            </div>
        </div>
    )
}

export default ProfileReviews;
